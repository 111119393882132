// @ts-ignore
import baguetteBox from 'baguettebox.js';
import Swiper, { Navigation, Pagination } from 'swiper';

export default () => {
    document.querySelectorAll('.swiper-container-wly').forEach((el) => {
        let init = false;

        const swiper = new Swiper(el as HTMLElement, {
            modules: [Navigation, Pagination],
            // Optional parameters
            direction: 'horizontal',
            loop: true,
            speed: 1400,
            autoplay: {
                delay: 7000
            },
            grabCursor: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        });

        swiper.on('init', () => {
            baguetteBox.run('.swiper-wrapper');
        });

        el.addEventListener('lazyloaded', () => {
            if (!init) {
                swiper.init();
                init = true;
            }

            swiper.updateAutoHeight();
        });
    });
};
