import $ from 'jquery';

$(() => {
    $(document).ready(() => {
        if ($(window).scrollTop() > 300) {
            $('body').addClass('fixed-header');
        } else {
            $('body').removeClass('fixed-header');
        }
    })

    // Scroll to
    $(document).on('click', 'a[href]:not([href^="mailto:"], [href$="#"])', function(event) {
        function isValidSelector(selector) {
            if (typeof selector !== 'string') {
                return false;
            }
            try {
                $(selector);
            } catch (error) {
                return false;
            }
            return true;
        }

        const href = $(this).attr('href');
        const currentLink = $('.navbar-nav [href="' + window.location.pathname + window.location.hash + '"]');
        // Extrahiere den Hash-Teil der URL
        const hashIndex = href.indexOf('#');

        if (hashIndex !== -1) {
            const hash = href.substring(hashIndex);
            if (isValidSelector(hash)) {
                if ($(hash).length && hash !== '#wlymmenu' && hash !== '#') {
                    event.preventDefault();
                    currentLink.parent().removeClass('active');
                    $(this).parent().addClass('active');
                    const baseUrl = window.location.href.split('#')[0]
                    history.replaceState({}, "", baseUrl + hash);
                    $('html, body').animate({
                        scrollTop: $(hash).offset().top,
                    }, 500);
                }
            }
        }
    });



    // Navigation Touch Geräte
    $('#headerNavbar ul.navbar-nav > li.nav-menu > a').on('touchend', function (e) {
        if (!$(this).parent().hasClass('hovered')) {
            e.preventDefault();
            $(this).parent().toggleClass('hovered');
            $(this).parent().siblings().removeClass('hovered');
        }
    });


    $(window).on('scroll', function () {
        if ($(window).scrollTop() > 300) {
            $('body').addClass('fixed-header');
        } else {
            $('body').removeClass('fixed-header');
        }
    });

    $('.geodata__header').click(function () {
        $(this).toggleClass('active');
    });
    $('#headerNavbar .nav-item').hover(function () {
        $('.geodata__header').removeClass('active');
    });
});
