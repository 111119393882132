import { startAlpine } from '@/alpine/boot';
import { registerComponent } from '@/alpine/helper';

import { FixedHeaderComponent } from '@/alpine/components/header';
import { FormComponent } from '@/alpine/components/form';

registerComponent('FixedHeader', FixedHeaderComponent);
registerComponent('Form', FormComponent);

startAlpine();
