import $ from 'jquery';

export default (container?: HTMLElement) => {
    (container ?? document).querySelectorAll('.app-accordion').forEach((el) => {
        if ((el as any).hasAccordion === true) {
            return;
        }

        (el as any).hasAccordion = true;

        const items = el.querySelectorAll('.accordion__item');

        const makeItemActive = (activeItem: Element) =>
            items.forEach((item) => {
                if (item.classList.contains('active') || item !== activeItem) {
                    item.classList.remove('active');
                    $(item.querySelector('.accordion__content')!).slideUp();
                } else {
                    item.classList.add('active');
                    $(item.querySelector('.accordion__content')!).slideDown();
                }
            });

        items.forEach((item) => {
            item.querySelector('.accordion__title')?.addEventListener('click', () => makeItemActive(item));
        });
    });
};
